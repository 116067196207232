import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../services/config'
import { TokenHeader } from '../../services/headers'
import { Switch } from '@material-ui/core'
import { Table, } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import SVG from "react-inlinesvg";
import { Paper } from '@material-ui/core'
import AddType from './AddType/AddType'
import EditType from './EditType/EditType'

const CategoryTypes = (props) => {
  const { id } = props.match.params

  const [categoryDetails,setcategoryDetails] = useState({})
  const [addmodalVisible, setaddmodalVisible] = useState(false)
  const [editmodalVisible, seteditmodalVisible] = useState(false)
  const [selectedType, setselectedType] = useState({})
  const [types, settypes] = useState([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    getCategory()
  }, [])


  const getCategory = () => {

    enableLoading()

    fetch(`${baseUrl}/admin/list/category`, {
      method: 'GET',
      headers: TokenHeader
    }).then(res => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setcategoryDetails()
          let filterdata = data.payload.filter(item => item.id == id)
          settypes(filterdata[0]?.types)
          disableLoading()
          // console.log("List", data.payload)
        }
      })
      .catch(err => {
        console.log(err)
        enableLoading()
      })
  }

  const enableLoading = () => {
    setloading(true)
  }
  const disableLoading = () => {
    setloading(false)
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } };




  const OnchangeSwitch = (id) => {


    fetch(`${baseUrl}/admin/list/category/type/toggle-status/${id}`, {
      method: 'GET',
      headers: TokenHeader,
    }).then((resp) => {
      resp.json().then(data => {
        console.log(data)
        getCategory()
      })
    }).catch((err) => {
      console.log(err)
    })


  }
  const editType = (type) => {
    setselectedType(type)
    seteditmodalVisible(true)
  }

  return (
    <Paper className='p-5'>
      <div className='blog__header2'>
        <h1>Types</h1>

        <button
          onClick={() => setaddmodalVisible(true)}
          className='btn btn-primary'>
          Add new Type
        </button>

      </div>
{/* 
      <div>
        <img
          // alt={category.name}
          style={{
            height: 100,
            width: 100
          }} src={category.poster ? category.poster.original_url : ''} />
      </div> */}
      <Table responsive>
        <thead className='table' style={{ backgroundColor: '#DCDFE2' }}>
          <th style={{ textAlign: "start", color: 'black' }}>ID</th>
          <th style={{ textAlign: "start", color: 'black' }}>Name</th>
          <th style={{ textAlign: "start", color: 'black' }}>Status</th>
          <th style={{ textAlign: "start", color: 'black' }}>Edit</th>
        </thead>
        <tbody>
          {
            types.map((category, index) => (
              <tr key={category.id}>
                <td>{index + 1}</td>
                <td id='text-card'>{category.name}</td>
                <td style={{ textAlign: "start" }}>
                  <Switch
                    onChange={(e) => OnchangeSwitch(category.id)}
                    {...label} defaultChecked={category.active == 1 ? true : false} />
                  {/* <div className={category.active == 1 ? 'box2' : 'box'}></div> */}
                </td>
                <td>

                  <a className="btn btn-icon btn-light btn-sm">
                    <span
                      onClick={() => editType(category)}

                      className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Write.svg"
                        )}
                      ></SVG>
                    </span>
                  </a>
                </td>



              </tr>
            ))
          }

        </tbody>
      </Table>
      {
        loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className='spinner spinner-success spinner-large'></span>
        </div>
      }
      <AddType
        categoryId={id}
        reload={getCategory}
        visible={addmodalVisible}
        hide={() => setaddmodalVisible(false)}
      />

      <EditType
        type={selectedType}
        reload={getCategory}
        hide={() => seteditmodalVisible(false)}
        editvisible={editmodalVisible}
      />
    </Paper>
  )
}

export default CategoryTypes