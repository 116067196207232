import { Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import axios from 'axios'
import SVG from "react-inlinesvg";
import DeleteModal from '../../components/DeleteUser/DeleteModal'
import { baseUrl } from '../../services/config'
import { TokenHeader } from '../../services/headers'
const Inquiries = () => {
    const history = useHistory()
    const [list,setlist]=useState([])
const [loading,setloading]=useState()
    useEffect(() => {
        getInquires()
    }, [])
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedtaxid,setselectedtaxid]=useState("")
    const getInquires = () => {
        setloading(true)
        axios({
            url: `${baseUrl}/admin/inquiries`,
            method: 'GET',
            headers: TokenHeader,
            responseType: 'json'
        }).then((resp) => {
            console.log(resp.data)
            setlist(resp.data.payload)
            setloading(false)
        })
            .catch(err => {
                console.log(err)
                setloading(false)

            })

    }
    const blogShotner=(des)=>{
        if(des.length>50){
           return des.slice(0,50)+'...'
        }
        else{
            return des
        }
    }
    const deleteAction = (id) => {
        setselectedtaxid(id);
        setDeleteModal(true);
      };
      const viewInquire = (id) => {
        history.push(`view-inquire/${id}`);
    }
  return (
    <>
       <Paper className="p-5">
            <div className='container'>
                <div className='blog__header'>
                    <h1>User Inquiries</h1>

                </div>

                <Table className='mt-5' responsive>
                    <thead className='table' style={{ backgroundColor: '#DCDFE2' }}>
                        <th style={{ textAlign: "start", color: 'black' }}>ID</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Subject</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Message</th>
                        <th style={{ textAlign: "start", color: 'black' }}>View</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Delete</th>
                    </thead>
                    <tbody>
                        {
                            list.map((category, index) => (
                                <tr key={category.id}>
                                    <td>{index + 1}</td>
                                    <td id='text-card'>{category.subject}</td>
                                    <td style={{textAlign:"justify"}} id='text-card'>{ blogShotner(category.message)}</td>

                                 
                                    <td>
                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span
                                                onClick={() => viewInquire(category.id)}

                                                className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Devices/Display2.svg"
                                                    )}
                                                ></SVG>
                                            </span>
                                        </a>

                                    </td>
                                    <td>
                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span
                                                onClick={() => deleteAction(category.id)}

                                                className="svg-icon svg-icon-md svg-icon-danger">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Home/Trash.svg"
                                                    )}
                                                ></SVG>
                                            </span>
                                        </a>

                                    </td>

                                </tr>
                            ))
                        }

                    </tbody>
                </Table>

                {
                    loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span className='spinner spinner-success spinner-large'></span>
                    </div>
                }
             

            </div>
        </Paper>
        <DeleteModal
        title={"Delete Inquire"}
        description={"Are you sure you want to delete?"}
        close={() => setDeleteModal(false)}
        show={deleteModal}
        url={`inquiries/${selectedtaxid}`}
        reload={getInquires}
      />
    </>
  )
}

export default Inquiries