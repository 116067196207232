import React from 'react'
import { Modal } from 'react-bootstrap'

const ViewFeature = ({
    hide,
    show,
    featureObject
}) => {

    return (
        <Modal
            show={show}
            onHide={hide}
        >
            <Modal.Header>
                <Modal.Title>Feature Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div>

                        <b className='m-3'>Name: <b>{featureObject.name}</b></b>

                    </div>
                    <div>
                        <b className='m-3'>Placeholder: <b>{featureObject.placeholder}</b></b>
                    </div>
                    <div>
                        <b className='m-3'>Input Type: <b>{featureObject.input_type}</b></b>
                    </div>
                    <h4 className='m-5'>Options</h4>
                    <ul style={{ listStyle: 'insides' }}>
                        {
                            featureObject?.options ?
                                featureObject?.options.map((opt, i) => (
                                    <li key={i}>{opt.name}</li>
                                ))
                                : null
                        }
                    </ul>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hide}
                    className="btn btn-danger">
                    Close
                </button>
            </Modal.Footer>
        </Modal>

    )
}

export default ViewFeature