import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
} from "../widgets";
import axios from "axios";
import { baseUrl } from "../../../app/services/config";
import { CircularProgress } from "@material-ui/core";
import { LoadingDialog } from "../controls";

export function Demo1Dashboard() {
  const [dashboadData, setDashboadData] = useState([]);
  const [grapghCounts, setGraphCounts] = useState([0]);
  const [graphLabels, setGraphLabels] = useState(['']);
  const [loading,setloading]=useState(false);
  let token = localStorage.getItem('userToken')

  useLayoutEffect(() => {
    dashboardGetData();
  }, []);

  const dashboardGetData = async () => {
    setloading(true);
    await axios
      .get(`${baseUrl}/admin/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setloading(false);
        console.log("response from server", res.data);
        if (res.data.metadata.success) {
          setDashboadData(res.data.payload);
          setGraphCounts(res.data.payload.property_stats.counts)
          setGraphLabels(res.data.payload.property_stats.labels)
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  if(loading){
    return <LoadingDialog isLoading={loading} text={"Please wait..."} />
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <MixedWidget1 className="card-stretch gutter-b" data={dashboadData} grapghCounts={grapghCounts} graphLabels={graphLabels}/>
        </div>
        <div className="col-lg-6 col-xxl-4">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-6 col-xxl-4">
          <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
        </div>

        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <ListsWidget1 className="card-stretch gutter-b" />
        </div>
        <div className="col-xxl-8 order-2 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div> */}
      </div>
      {/* <div className="row">
        <div className="col-lg-4">
          <MixedWidget14 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
      </div> */}
    </>
  );
}
