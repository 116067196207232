import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from "yup";
import { TokenHeader } from '../../services/headers';
import { baseUrl } from '../../services/config';
import axios from 'axios';
const EditCategory = ({ category, visible, hide, reload }) => {
    
    const [loading, setloading] = useState(false)
    const [name, setName] = useState(category.name)
    const [poster, setposter] = useState(category?.poster)
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(name)


        enableLoading()


        let fdata = new FormData()
        fdata.append('name', name)
        // fdata.append('poster', poster)



        axios.post(`${baseUrl}/admin/categories/${category.id}?_method=PUT`, fdata, { headers: TokenHeader })
        .then(data => {
            disableLoading()
            console.log(data)
            hide()
            reload()

        })

            .catch((err) => {
                console.log(err)
            })

        // fetch(`${baseUrl}/admin/list/category/${category.id}?_method=PUT`, {
        //     method: 'POST',
        //     headers: TokenHeader,
        //     body: fdata
        // }).then((resp) => {
        //     resp.json().then(data => {
        //         disableLoading()
        //         console.log(data)
        //         hide()
        //         reload()

        //     })
        // }).catch((err) => {
        //     console.log(err)
        // })
    }

    const enableLoading = () => {
        setloading(true)
    }
    const disableLoading = () => {
        setloading(false)
    }

    return (
        <Modal onHide={() => hide()} animation={true} show={visible}>
            <Modal.Header closeButton={true} closeLabel="close">
                <Modal.Title>
                    Update Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework">
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            // value={}
                            placeholder="Category Name"
                            // onChange={(e) => initialValues.name = e.target.value}
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            className={`form-control form-control-solid h-auto py-5 px-6`}
                            defaultValue={category.name}
                            name="name"

                        />

                    </div>
                    {/* <input
                        onChange={(e) => setposter(e.target.files[0])} type={'file'}
                    /> */}

                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            id="kt_login_signin_submit"
                            type="submit"

                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        >
                            <span>Update</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </form>
            </Modal.Body>

        </Modal>
    )
}

export default EditCategory