import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import PropertyCard from '../../components/PropertyCard/PropertyCard'
import { baseUrl } from '../../services/config'
import { TokenHeader } from '../../services/headers'
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import SkeletonCard from '../../components/SkeletonCard/SkeletonCard'
import UsePagination from '../../components/usePagination/usePagination'


const Property = (props) => {

    let token = localStorage.getItem('userToken')
    const [loading, setloading] = useState(false)
    const [properties, setproperties] = useState([])
    const [links, setlinks] = useState([])

    useEffect(() => {
        getProperty(`${baseUrl}/admin/properties`)
    }, [])

    const getProperty = (url) => {
        setloading(true)
        axios({
            url: url,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'json'
        }).then((resp) => {
            // console.log(resp.data)
            setproperties(resp.data.payload.data)
            setlinks(resp.data.payload.links)
            setloading(false)
        })
            .catch(err => {
                console.log(err)
                setloading(false)

            })

    }

    const onPaginate = link => {
        getProperty(link?.url)
        window.scroll({
            behavior: 'smooth',
            top: 0
        })

    }


    return (
        <>

            <div className='row'>
                {
                    loading &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                        <SkeletonCard />

                    ))
                }

                {
                    properties ?

                        properties.map((item, index) => (
                            <div className='col-md-3'>
                                <PropertyCard reload={() => getProperty(`${baseUrl}/admin/properties`)} item={item} />
                            </div>
                        ))

                        :
                        <h1>Not found</h1>
                }

            </div>
            <UsePagination
                links={links}
                onPaginate={onPaginate}
            />
        </>

    )
}

export default Property








