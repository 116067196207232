import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../services/config'
import { TokenHeader } from '../../services/headers'
import Paper from '@material-ui/core/Paper';
import { Table, } from 'react-bootstrap'
import { appColors } from '../../services/colors'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Switch } from '@material-ui/core'
import DeleteModal from '../../components/DeleteUser/DeleteModal';

const Users = () => {


    let token = localStorage.getItem('userToken')

    const [loading, setloading] = useState(false)
    const [categoriesList, setcategoriesList] = useState([])
    const [list1, setList1] = useState([])
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedtaxid, setSelectedTaxId] = useState("");

    useEffect(() => {
        getCategory()
    }, [])

    const getCategory = () => {

        enableLoading()

        fetch(`${baseUrl}/admin/users`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {

                    setList1(data.payload)
                    disableLoading()

                }
            })
            .catch(err => {
                console.log(err)
                enableLoading()
            })
    }

    const enableLoading = () => {
        setloading(true)
    }
    const disableLoading = () => {
        setloading(false)
    }


    const deleteAction = (id) => {
        setSelectedTaxId(id);
        setDeleteModal(true);
    };



    const label = { inputProps: { 'aria-label': 'Switch demo' } };



    const OnchangeSwitch = (id) => {


        fetch(`${baseUrl}/admin/user/toggle-status/${id}`, {
            method: 'GET',
            headers: TokenHeader,
        }).then((resp) => {
            resp.json().then(data => {
                console.log(data)
                getCategory()
            })
        }).catch((err) => {
            console.log(err)
        })


    }



    return (
        <>
            <Paper className='p-5'>
                <div className='row justify-content-between p-5'>
                    <h1 className='pl-5'>Users</h1>
                    {/* <button
                    onClick={() => setmodalVisable(true)}
                    className='btn btn-info pr-5'>
                    Add Category
                </button> */}
                </div>
                <Table responsive>
                    <thead className='table' style={{ backgroundColor: '#DCDFE2' }}>
                        <th style={{ textAlign: "start", color: 'black' }}>ID</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Name</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Email</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Delete</th>
                    </thead>
                    <tbody>
                        {
                            list1.map((category, index) => (
                                <tr key={category.id}>
                                    <td>{index + 1}</td>
                                    <td id='text-card'>{category.name}</td>
                                    <td id='text-card'>{category.email}</td>
                                    <td style={{ textAlign: "start" }}>

                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span
                                                // onClick={() => deleteBlog(category.id)}

                                                className="svg-icon svg-icon-md svg-icon-danger">
                                                <SVG
                                                    onClick={() => deleteAction(category.id)}
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Home/Trash.svg"
                                                    )}
                                                ></SVG>
                                            </span>
                                        </a>
                                        {/* <div className={category.active == 1 ? 'box2' : 'box'}></div> */}
                                    </td>



                                </tr>
                            ))
                        }

                    </tbody>
                </Table>
                {
                    loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span className='spinner spinner-success spinner-large'></span>
                    </div>
                }



            </Paper>
            <DeleteModal
                title={"Delete User"}
                description={"Are you sure you want to delete?"}
                close={() => setDeleteModal(false)}
                show={deleteModal}
                id={selectedtaxid}
                url={""}
                reload={getCategory}
            />
        </>
    )
}

export default Users