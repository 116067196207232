import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CategoryCard from '../../components/CategoryCard/CategoryCard'
import { baseUrl } from '../../services/config'
import { TokenHeader } from '../../services/headers'
import Paper from '@material-ui/core/Paper';
import { Table, Modal, Accordion, Card, Button, ToggleButton } from 'react-bootstrap'
import { appColors } from '../../services/colors'
import './style.css'
import SVG from "react-inlinesvg";
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from "formik";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Switch } from '@material-ui/core'
import EditCategory from '../../components/EditCategory/EditCategory'
import DeleteModal from '../../components/DeleteModal/DeleteModal'
import toast from 'react-hot-toast'


const Category = () => {

    const initialValues = { name: "" };
    const history = useHistory()
    const [loading, setloading] = useState(false)
    const [categoriesList, setcategoriesList] = useState([])
    const [modalVisable, setmodalVisable] = useState(false)
    const [editModal, seteditModal] = useState(false)
    const [selectedCategory, setselectedCategory] = useState({})
    const [list1, setList1] = useState([])
    const [poster, setposter] = useState('')
    const [selectedId, setselectedId] = useState('')

    const [deleteModal, setdeleteModal] = useState(false)


    const AddCategorySchema = Yup.object().shape({
        name: Yup.string().required('Please enter category name')
    });

    useEffect(() => {
        getCategory()
    }, [])

    const getCategory = () => {
        enableLoading()
        fetch(`${baseUrl}/admin/categories`, {
            method: 'GET',
            headers: TokenHeader
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setcategoriesList(data.payload)
                    setList1(data.payload)
                    disableLoading()
                    console.log("List", data.payload)
                }
            })
            .catch(err => {
                console.log(err)
                enableLoading()
            })
    }

    const enableLoading = () => {
        setloading(true)
    }
    const disableLoading = () => {
        setloading(false)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: AddCategorySchema,
        onSubmit: (values, { setStatus, setSubmitting ,resetForm}) => {
            enableLoading();
            const fdata = new FormData()
            fdata.append('name', values.name)
            axios.post(`${baseUrl}/admin/categories`, fdata, {
                headers: TokenHeader
            }).then(res => {
                disableLoading()
                setmodalVisable(false)
                resetForm()
                setSubmitting(false)
                getCategory()
                toast.success(res.data.metadata.message)
            })
                .catch((err) => {
                    console.log(err)
                })

        },
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };



    const OnchangeSwitch = (id) => {


        fetch(`${baseUrl}/admin/list/category/toggle-status/${id}`, {
            method: 'GET',
            headers: TokenHeader,
        }).then((resp) => {
            resp.json().then(data => {
                console.log(data)
                getCategory()
            })
        }).catch((err) => {
            console.log(err)
        })


    }


    const editCategory = (category) => {
        setselectedCategory(category)
        seteditModal(true)
    }



    const ViewType = (id) => {
        history.push(`/category-type/${id}`)
    }




    const deleteCategory = id => {
        setselectedId(id)
        setdeleteModal(true)
    }




    return (
        <Paper className='p-5'>
            <div className='row justify-content-between p-5'>
                <h1 className='pl-5'>Categories</h1>
                <div>
                    <button
                        style={{ backgroundColor: '#7337EE', color: 'white' }}
                        onClick={() => setmodalVisable(true)}
                        className='btn pr-5 mr-5'>
                        Add Category
                    </button>
                    <Link
                        style={{ backgroundColor: '#7337EE', color: 'white' }}
                        to={"/attach-feature"}
                        className='btn pr-5'>
                        Attach Features
                    </Link>
                </div>
            </div>
            <Table responsive>
                <thead className='table' style={{ backgroundColor: '#DCDFE2' }}>
                    <th style={{ textAlign: "start", color: 'black' }}>ID</th>
                    <th style={{ textAlign: "start", color: 'black' }}>Name</th>
                    {/* <th style={{ textAlign: "start", color: 'black' }}>Image</th> */}
                    <th style={{ textAlign: "start", color: 'black' }}>Edit</th>
                    {/* <th style={{ textAlign: "start", color: 'black' }}>View</th> */}
                    <th style={{ textAlign: "start", color: 'black' }}>Delete</th>

                </thead>
                <tbody>
                    {
                        categoriesList.map((category, index) => (
                            <tr key={category.id}>
                                <td>{index + 1}</td>
                                <td id='text-card'>{category.name}</td>
                                {/* <td>
                                    <img
                                        // alt={category.name}
                                        style={{
                                            height: 50,
                                            width: 50
                                        }} src={category.poster ? category.poster.original_url : ''} />
                                </td> */}

                                <td>
                                    <a className="btn btn-icon btn-light btn-sm">
                                        <span
                                            onClick={() => editCategory(category)}

                                            className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Communication/Write.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                    </a>

                                </td>
                                {/* <td>
                                    <a className="btn btn-icon btn-light btn-sm">
                                        <span
                                            onClick={() => ViewType(category.id)}

                                            className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Display2.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                    </a>
                              -
                                </td> */}
                                <td

                                    onClick={() => deleteCategory(category.id)}
                                    style={{ textAlign: "start" }}>
                                    <a className="btn btn-icon btn-light btn-sm">
                                        <span

                                            className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Home/Trash.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                    </a>
                                </td>

                            </tr>
                        ))
                    }

                </tbody>
            </Table>
            {
                loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span className='spinner spinner-success spinner-large'></span>
                </div>
            }
            {/* <Accordion defaultActiveKey="0">
                
                {
                    categoriesList.map(category => (
                        <Card>
                            <Card.Header>
                                <div className='d-flex align-items-center'>
                                    <Accordion.Toggle style={{ padding: "12px" }} as={Card.Header} variant="link" eventKey={category.id}>
                                        <h6>{category.name}</h6>

                                    </Accordion.Toggle>
                                    <div>
                                        <button>Add</button>
                                    </div>

                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey={category.id}>
                                <Card.Body>Hello! I'm the body</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))
                }
            </Accordion> */}
            <Modal onHide={() => setmodalVisable(false)} animation={true} show={modalVisable}>
                <Modal.Header closeButton={true} closeLabel="close">
                    <Modal.Title>
                        Add Category
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework">
                        {formik.status ? (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                        ) : (
                            null
                            // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                            //   <div className="alert-text ">
                            //     Use account <strong>admin@demo.com</strong> and password{" "}
                            //     <strong>demo</strong> to continue.
                            //   </div>
                            // </div>
                        )}
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Category Name"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "name"
                                )}`}
                                name="name"
                                {...formik.getFieldProps("name")}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.name}</div>
                                </div>
                            ) : null}
                        </div>
                        {/* 
                        <div>
                            <input className='form-control' type={'file'} onChange={(e) => setposter(e.target.files[0])} />
                        </div> */}

                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={formik.isSubmitting}
                                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                            >
                                <span>Add</span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <EditCategory
                visible={editModal}
                category={selectedCategory}
                hide={() => seteditModal(false)}
                reload={getCategory}
            />

            <DeleteModal
                show={deleteModal}
                hideModal={() => setdeleteModal(false)}
                url={`${baseUrl}/admin/categories/${selectedId}`}
                reload={getCategory}

            />
        </Paper>
    )
}

export default Category