let token = localStorage.getItem('userToken')
export const headers = {
    'Content-Type':'application/json'

}


export const mediaHeader = {
    'Content-Type':'multipart/form-data',
    'Authorization':`Bearer ${token}`,

}


export const TokenHeader = {
    'Content-Type':'application/json',
    'Authorization':`Bearer ${token}`,
}