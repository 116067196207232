import { CircularProgress, LinearProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import '../Blog/blog.css'
import { baseUrl } from "../../services/config";
import { TokenHeader } from "../../services/headers";

const ViewInquire = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [blogDetails, setBlogDetails] = useState({});
  const [list,setlist]=useState([])

  useEffect(() => {
    getInquires()
}, [])
  const getInquires = () => {
    setloading(true)
    axios({
        url: `${baseUrl}/admin/inquiries`,
        method: 'GET',
        headers: TokenHeader,
        responseType: 'json'
    }).then((resp) => {
        console.log(resp.data)
        setlist(resp.data.payload)
     const single=resp.data.payload.find((item)=>(
           item.id==id
        ))
        setBlogDetails(single)
        setloading(false)
    })
        .catch(err => {
            console.log(err)
            setloading(false)

        })

}

   

  return (
    <div className="container-fluid">
      {loading ? (
        <div className="row d-flex">
          <CircularProgress />
        </div>
      ) : blogDetails ? (
        <>
          <div className="row">
            <div className="col">
              <label className="form-label font-weight-bold font-size-h6">
                Name
              </label>
              <div className="bg-white p-4 rounded">
                {blogDetails.name}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label font-weight-bold font-size-h6">
                Email
              </label>
              <div className="bg-white p-4 rounded">
                {blogDetails.email}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="form-label font-weight-bold font-size-h6">
                Subject
              </label>
              <div className="bg-white p-4 rounded">
                {blogDetails.subject}
              </div>
            </div>
          </div>


          <div className="row mt-5">
            <div className="col">
              <label className="form-label font-weight-bold font-size-h6">
              Message
              </label>
              {/* <div className='form-control font-weight-bold font-size-h4'> */}
              <div
                dangerouslySetInnerHTML={{ __html: blogDetails.message }}
                className={"bg-white p-4 rounded"}
              ></div>

              {/* </div> */}
            </div>
          </div>


          <div className="row mt-5 h-25 ">
            {blogDetails?.images?.length > 0 ?
              blogDetails.images.map((item, index) => {
                return (
                  <>
                    <div className="col" >
                      <img src={item.original_url} className={"img-blog-view"} alt={"..."} />
                    </div>
                  </>
                );
              }):null
            }
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ViewInquire;
