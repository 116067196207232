import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { baseUrl } from '../../../services/config'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import { TokenHeader } from '../../../services/headers'
import { Paper } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'

const ShowProperty = (props) => {

    const [loading, setloading] = useState(false)
    const [property, setProperty] = useState([])
    const [images, setImages] = useState([])

    const { id } = props.match.params

    const getProperty = () => {
        setloading(true)
        axios({
            url: `${baseUrl}/admin/properties/${id}`,
            method: 'GET',
            headers: TokenHeader,
            responseType: 'json'
        }).then((resp) => {
            console.log(resp.data.payload)
            setProperty(resp.data.payload)
            setImages(resp.data.payload.images)
            setloading(false)
        }).catch(err => {
            console.log(err)
            setloading(false)
        })
    }

    useEffect(() => {
        getProperty()
    }, [])

    return (
        <div>
            {
                loading &&
                <div>
                    <Spinner style={{ margin: 'auto auto' }} animation='border' variant='primary'></Spinner>
                </div>

            }
            <Link
                to="/property"
                style={{ border: 'none', margin: 5 }}
            >
                {/* <img src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-to-left.svg')} /> */}
            </Link>
            <Carousel fade>
                {
                    images ?
                        images.map(image => (
                            <Carousel.Item style={{ height: "380px" }} key={image.id}>
                                <img
                                    className="d-block w-100"
                                    src={image.original_url}
                                    alt="Image"
                                />
                            </Carousel.Item>
                        ))
                        :
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg"
                                alt="Image"
                            />
                        </Carousel.Item>
                }
            </Carousel>
            <Paper className='row p-5 mt-5'>
                <h1 className='m-5'>{property.title}</h1>
                <p className='m-5' style={{textAlign:"justify"}}>{property.description}</p>
            </Paper>
            <hr />
            <Paper className="row p-5">
                <div className="col-lg-6">
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                    <th style={{ color: "black" }}>Classification</th>
                                    <td>{property?.classification?.name}</td>
                                </tr>
                                <tr>
                                    <th style={{ color: "black" }}>City</th>
                                    <td>{property?.city?.name}</td>
                                </tr>
                                <tr>
                                    <th style={{ color: "black" }}>Purpose</th>
                                    <td>{property.purpose == 1 ? "Sale" : "Rent"}</td>
                                </tr>
                                <tr>
                                    <th style={{ color: "black" }}>Occupied Till</th>
                                    <td>{property.occupied_till ?? "Not occupied"}</td>
                                </tr>
                                {
                                    property.purpose == "1" ?
                                        <>

                                            <tr>
                                                <th style={{ color: "black" }}>Price</th>
                                                <td>{property?.sale?.price}</td>
                                            </tr>

                                            {
                                                property?.sale?.installment_available == 1 ?
                                                    <>
                                                        <tr>
                                                            <th style={{ color: "black" }}>Number of installment</th>
                                                            <td>{property?.sale?.installment?.number_of_installment}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ color: "black" }}>Advance payment</th>
                                                            <td>{property?.sale?.installment?.advance_payment}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ color: "black" }}>Monthly Installment</th>
                                                            <td>{property?.sale?.installment?.monthly_installment}</td>
                                                        </tr>
                                                    </>

                                                    : null
                                            }
                                        </>

                                        :
                                        null

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th style={{ color: "black" }}>Address</th>
                                    <td>{property.address}</td>
                                </tr>
                                <tr>
                                    <th style={{ color: "black" }}>Size</th>
                                    <td>{`${property.size} squere feet`}</td>
                                </tr>
                                <tr>
                                    <th style={{ color: "black" }}>Category</th>
                                    <td>{property?.category?.name}</td>
                                </tr>
                                <tr>
                                    <th style={{ color: "black" }}>Type</th>
                                    <td>{property?.type}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default ShowProperty