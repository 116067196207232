import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { LoadingDialog } from "../../../_metronic/_partials/controls";
import ListDividers from "../../components/ClassificationComponents/ListDividers";
import { baseUrl } from "../../services/config";
import { mediaHeader, TokenHeader } from "../../services/headers";
import axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'green',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: 'green',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});




const Classifications = () => {
  const [classficationsId, setClassficationsId] = useState([]);
  const [classfications, setClassfications] = useState([]);
  const [attachedCategories, setAttachCategories] = useState([]);
  const [attachedList, setAttachList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesloading, setLoadingCategories] = useState(false);
  const [state, setState] = React.useState(false);

  let token = localStorage.getItem("userToken");

  useEffect(() => {
    getClassifications();

  }, []);

  const getClassifications = async () => {
    setLoading(true);
    await fetch(`${baseUrl}/admin/classifications`, {
      method: "GET",
      headers: TokenHeader,
    })
      .then((res) =>
        res.json().then((res) => {
          setLoading(false);
     
          setClassfications(res.payload);
        })
      )

      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };



  const onCategoryClickHandler = async (id) => {
    setClassficationsId(id);
    setAttachCategories([]);
    setAttachList([]);
    setLoadingCategories(true);
    await fetch(`${baseUrl}/admin/classifications/${id}/categories`, {
      method: "GET",
      headers: TokenHeader,
    })
      .then((res) =>
        res.json().then((res) => {
          setLoadingCategories(false);
          console.log(res.payload);
          setAttachCategories(res.payload);
        })
      )

      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const attactListHandler = (id, check) => {
    let tempArr = [...attachedCategories];
    let newArr = [];
    if (check) {
      newArr = tempArr.map((obj) => {
        if (obj.id === id) {
          return { ...obj, attach: true };
        }

        return obj;
      });
      setAttachCategories(newArr);
      console.log("NewArr ====> " + JSON.stringify(newArr));
    } else {
      newArr = tempArr.map((obj) => {
        if (obj.id === id) {
          return { ...obj, attach: false };
        }

        return obj;
      });
      setAttachCategories(newArr);
      console.log("NewArr ====> " + JSON.stringify(newArr));
    }
    const updatedArr = newArr.filter((item) => item.attach == true);
    console.log(
      "Update====> ",
      updatedArr.map((item) => item.id)
    );
    setAttachList(updatedArr.map((item) => item.id));
  };





  const onSaveAttachHandler = async () => {
    setLoadingCategories(true)
    const updatedArr = attachedCategories.filter((item) => item.attach == true);

    let newarr = updatedArr.map((item) => item.id)

    const data = new FormData();


    for (let i = 0; i < newarr.length; i++) {
      data.append(`categoryIds[${i}]`, newarr[i]);
    }

    for (var pair of data.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    await axios
      .post(
        `${baseUrl}/admin/classifications/${classficationsId}/attach-categories`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoadingCategories(false)
        console.log("response from server", res.data);
        // getClassifications()
      })
      .catch((err) => {
        setLoadingCategories(false)
        console.log(err);
      });
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  if (loading) {
    return <CircularProgress />;
  }



  const handleChange = (e, item, index) => {

    let arr = [...attachedCategories]
    let newVal = e.target.checked
    arr[index].attach = newVal
    setAttachCategories(arr)

    // console.log(item)
    // console.log(newVal)
    // attachedCategories 


  };




  return (
    <div className="continer-fluid">
      <div className="row">
        <div className="col col-md-4 col-lg-4">
          <h2 className="shadow-sm p-3 mb-5 bg-white rounded">Select Classifications</h2>
          <ListDividers
            data={classfications}
            loader={categoriesloading}
            onClick={onCategoryClickHandler}
            selectedItem={classficationsId}
          />
        </div>
        <div className="col col-md-8 col-lg-8">
          {categoriesloading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <h2 className="shadow-sm p-3 mb-5 bg-white rounded">Attach Category</h2>
              <div className="bg-white">
                <Table responsive>
                  <thead className="table" style={{ backgroundColor: "#DCDFE2" }}>
                    <th style={{ textAlign: "start", color: "black" }}>ID</th>
                    <th style={{ textAlign: "start", color: "black" }}>Name</th>
                    <th style={{ textAlign: "start", color: "black" }}>Status</th>
                  </thead>
                  <tbody>
                    {attachedCategories.map((category, index) => (
                      <tr key={category.id}>
                        <td>{index + 1}</td>
                        <td id="text-card">{category.name}</td>
                        <td style={{ textAlign: "start" }}>
                          <FormControlLabel
                            control={<IOSSwitch checked={category.attach} onChange={(e) => handleChange(e, category, index)} name="checkedB" />}
                          />
                          {/* <input checked={category.attach} type={'checkbox'} /> */}
                        </td>
                        {/* <td>
                                    <a className="btn btn-icon btn-light btn-sm">
                                        <span
                                            // onClick={() => editCategory(category)}

                                            className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Communication/Write.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                    </a>

                                </td> */}
                        {/* <td>
                                    <a className="btn btn-icon btn-light btn-sm">
                                        <span
                                            onClick={() => ViewType(category.id)}

                                            className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Display2.svg"
                                                )}
                                            ></SVG>
                                        </span>
                                    </a> */}
                        {/* <span
                                        onClick={() => ViewType(category.id)}
                                        style={{ cursor: 'pointer' }}
                                        className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                        />
                                    </span> */}
                        {/* </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
          <button
            className="btn btn-primary mt-10"
            onClick={onSaveAttachHandler}
            disabled={attachedCategories.length > 0 ? false : true}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Classifications;
