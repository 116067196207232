import { Paper } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Badge, Spinner, Table } from 'react-bootstrap'
import { baseUrl } from '../../services/config'
import { TokenHeader } from '../../services/headers'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import AddFeature from './AddFeature/AddFeature'
import DeleteModal from '../../components/DeleteModal/DeleteModal'
import EditFeature from './EditFeature/EditFeature'
import ViewFeature from './ViewFeature/ViewFeature'
import VisibilityIcon from '@material-ui/icons/Visibility';


const ViewAmenity = (props) => {


    const { id, slug } = props.match.params

    const [addModal, setaddModal] = useState(false)
    const [featuresList, setfeaturesList] = useState([])
    const [loading, setloading] = useState(false)
    const [selectedid, setselectedid] = useState('')
    const [selectedObj, setselectedObj] = useState({})
    const [deleteModal, setdeleteModal] = useState(false)
    const [editModal, seteditModal] = useState(false)
    const [viewModal, setviewModal] = useState(false)

    useEffect(() => {
        getAmentiyList()
    }, [])


    const getAmentiyList = () => {
        setloading(true)
        fetch(`${baseUrl}/admin/amenities/${id}/features`, {
            method: 'GET',
            headers: TokenHeader
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setfeaturesList(data.payload)
                    setloading(false)
                } else {
                    setloading(false)
                }
            }).catch(err => {
                setloading(false)
                console.log("geting features error", err)
            })

    }



    const deleteBlog = (id) => {
        setselectedid(id)
        setdeleteModal(true)
    }


    return (
        <div>
            <div className='row justify-content-between p-5'>
                <h1 className='pl-5'>{slug} Features </h1>
                <div>
                    <button
                        onClick={() => setaddModal(true)}
                        style={{ backgroundColor: '#7337EE', color: 'white' }}

                        className='btn pr-5 mr-5'>
                        Add Feature
                    </button>

                </div>
            </div>
            <Paper className='p-3'>


                <Table responsive >
                    <thead className='table' style={{ backgroundColor: '#DCDFE2' }}>
                        <tr>
                            <th style={{ textAlign: "start", color: 'black' }}>Sr.no</th>
                            <th style={{ textAlign: "start", color: 'black' }}>Name</th>
                            <th style={{ textAlign: "start", color: 'black' }}>Input Type</th>
                            <th style={{ textAlign: "start", color: 'black' }}>Placeholder</th>
                            <th style={{ textAlign: "start", color: 'black' }}>Regular Expression</th>
                            <th style={{ textAlign: "start", color: 'black' }}>Options</th>
                            <th style={{ textAlign: "start", color: 'black' }}>View</th>
                            <th style={{ textAlign: "start", color: 'black' }}>Edit</th>
                            <th style={{ textAlign: "start", color: 'black' }}>Delete</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            featuresList && featuresList.map((feature, i) => (
                                <tr key={feature.id}>
                                    <td>{i + 1}</td>
                                    <td>{feature.name}</td>
                                    <td>{feature.input_type}</td>
                                    <td>{feature.placeholder}</td>
                                    <td>{feature.regular_expression}</td>
                                    <td style={{ textAlign: "start" }}>
                                        {
                                            feature.options.map(op => (
                                                <Badge key={op} style={{ backgroundColor: "#E83D3D", margin: "2px", color: 'white', }} bg="primary">{op.name}</Badge>
                                            ))
                                        }
                                    </td>
                                    <td onClick={() => {
                                        setselectedObj(feature)
                                        setviewModal(true)
                                    }}>
                                        <VisibilityIcon style={{ color: "green",cursor:'pointer' }} />
                                    </td>
                                    <td>
                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span
                                                onClick={() => {
                                                    setselectedObj(feature)
                                                    seteditModal(true)
                                                }}

                                                className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Design/Edit.svg"
                                                    )}
                                                ></SVG>
                                            </span>
                                        </a>
                                    </td>
                                    <td>
                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span
                                                onClick={() => deleteBlog(feature.id)}

                                                className="svg-icon svg-icon-md svg-icon-danger">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Home/Trash.svg"
                                                    )}
                                                ></SVG>
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>

                {loading && <div className='text-center'><Spinner animation='border' /></div>}
                <AddFeature
                    visible={addModal}
                    hide={() => setaddModal(false)}
                    reload={getAmentiyList}
                    category={id}
                />

                <DeleteModal
                    show={deleteModal}
                    hideModal={() => setdeleteModal(false)}
                    reload={getAmentiyList}
                    url={`${baseUrl}/admin/features/${selectedid}`}
                />

                <EditFeature
                    category={id}
                    visible={editModal}
                    reload={getAmentiyList}
                    featureObject={selectedObj}
                    hide={() => seteditModal(false)}
                />
                <ViewFeature
                    featureObject={selectedObj}
                    hide={() => setviewModal(false)}
                    show={viewModal}

                />
            </Paper>
        </div>
    )
}

export default ViewAmenity