import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../services/config';

const DeleteModal = ({
    url,
    show,
    close,
    reload,
    id,
    title,
    description
}) => {

    const [loading, setloading] = useState(false)
    const authtoken = localStorage.getItem("userToken");

    const deleteThis = () => {
        setloading(true)
        let fetchurl = `${baseUrl}/admin/users/${id}`
        console.log(fetchurl)

        fetch(fetchurl, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                console.log(data)
                if (data.metadata.success) {
                    toast.success(data.metadata.message)
                    setloading(false)
                    close()
                    reload()
                }
                // toast.success
            }).catch(err => {
                console.log(err)
                setloading(false)

            })
    }


    return (
        <Modal
            show={show}
            onHide={close}
            animation={true}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {description}
            </Modal.Body>
            <Modal.Footer>
                {
                    loading ?
                        <Spinner animation='border' />

                        :
                        <>
                            <button
                                onClick={close}
                                className='btn btn-primary'>
                                Cancel
                            </button>
                            <button
                                onClick={deleteThis}
                                className='btn btn-danger'>
                                Delete
                            </button>
                        </>
                }
            </Modal.Footer>

        </Modal>
    )
}

export default DeleteModal