import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import DeleteModal from '../DeleteModal/DeleteModal';
import { baseUrl } from '../../services/config';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 10
  },
});



export default function PropertyCard({ item, reload }) {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [deleteModel, setDeleteModel] = React.useState(false);

  const history = useHistory()

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const showProperty = (id) => {
    history.push(`/showproperty/${id}`)

  }
  const titleShotner = (title) => {
    if (title.length > 20) {
      return title.slice(0, 20) + '...'
    } else {
      return title
    }
  }
  return (
    <>

      <Card
        // onClick={() => showProperty(item.id)}
        className={classes.root}>
        <CardActionArea>
          <CardMedia
            loading={'lazy'}
            component="img"
            alt={item.title}
            // height="140"
            style={{
              width: '100%',
              height: '150px'
            }}
            image={item.image ? item.image.original_url : 'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg'}
            title={item.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {titleShotner(item.title)}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" component="p">

          </Typography> */}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button onClick={() => showProperty(item.id)} size="small" style={{ color: "#3699FF" }}>
            View More
          </Button>
          <Button onClick={() => setDeleteModel(true)} size="small" color="secondary">
            Delete
          </Button>
        </CardActions>
      </Card>

      <DeleteModal
        method="POST"
        show={deleteModel}
        hideModal={() => setDeleteModel(false)}
        url={`${baseUrl}/admin/properties/${item.id}`}
        reload={() => reload()}
      />
    </>

  );
}
