import { CircularProgress, LinearProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../services/config";
import { TokenHeader } from "../../../services/headers";
import '../blog.css'
const ViewBlog = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [blogDetails, setBlogDetails] = useState({});

  useEffect(() => {
    getBlogDetails();
  }, []);

  const getBlogDetails = async () => {
    setloading(true);
    await axios({
      url: `${baseUrl}/admin/blogs/${id}`,
      method: "GET",
      headers: TokenHeader,
      responseType: "json",
    })
      .then((resp) => {
        console.log(resp.data);
        setBlogDetails(resp.data.payload);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  return (
    <div className="container-fluid">
      {loading ? (
        <div className="row d-flex">
          <CircularProgress />
        </div>
      ) : blogDetails ? (
        <>
          <div className="row">
            <div className="col">
              <label className="form-label font-weight-bold font-size-h6">
                Title
              </label>
              <div className="form-control font-weight-bold font-size-h4">
                {blogDetails.title}
              </div>
            </div>
          </div>


          <div className="row mt-5">
            <div className="col">
              <label className="form-label font-weight-bold font-size-h6">
                Details
              </label>
              {/* <div className='form-control font-weight-bold font-size-h4'> */}
              <div
                dangerouslySetInnerHTML={{ __html: blogDetails.description }}
                className={"bg-white p-4 rounded"}
              ></div>

              {/* </div> */}
            </div>
          </div>


          <div className="row mt-5 h-25 ">
            {blogDetails?.images?.length > 0 ?
              blogDetails.images.map((item, index) => {
                return (
                  <>
                    <div className="col" >
                      <img src={item.original_url} className={"img-blog-view"} alt={"..."} />
                    </div>
                  </>
                );
              }):null
            }
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ViewBlog;
