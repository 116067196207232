import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../../../services/config";
import { mediaHeader } from "../../../services/headers";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./addnewBlog.css"
import { toast } from "react-hot-toast";

const AddNewBlog = (props) => {
  const history = useHistory();

  const [title, settitle] = useState("");
  const [selectedImage, setselectedImage] = useState("");
  const [description, setdescription] = useState("");
  const [loading, setloading] = useState(false);
  const [images, setimages] = useState([]);
  const [show, setshow] = useState(false);
  const [showlist, setshowlist] = useState([]);

  let token = localStorage.getItem("userToken");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title == "" || description == "") {
      toast.error("please fill all fields");
    } else {
      createBlog();
    }
  };

  const onchangeFile = (event) => {
    // let arr = images
    // if (event.target.files && event.target.files[0]) {
    //     let reader = new FileReader();
    //     reader.onload = (e) => {
    //         arr.push(e.target.result)

    //         // setselectedImage(e.target.result);
    //     };
    //     reader.readAsDataURL(event.target.files[0]);
    // }
    // setimages(arr)
    // setselectedImage(file.target.files[0])
    let arr = images;
    arr.push(event.target.files[0]);
    setimages(arr);

    // console.log(images)
  };

  // const onchangeFile = (e) => {
  //     var file = e.target.files[0];
  //     var reader = new FileReader();
  //     reader.onload = (e) => {
  //       //Image URL e.target.result
  //       console.log(e.target.result)
  //     //   this.setState({
  //     //     form:{
  //     //       frontDesign: [e.target.result]
  //     //     }
  //     //   })
  //     }
  //     reader.readAsDataURL(file);
  //   }

  //   const onImageChange = event => {
  //     if (event.target.files && event.target.files[0]) {
  //       let img = event.target.files[0];
  //     let url =URL.createObjectURL(img)
  //     console.log(url)
  //     }
  //   };

  const handleChange = (e) => {
    let img = e.target.files[0];
    const imagesArray = [...images];
    let isValid = "";

    // for (let i = 0; i < e.target.files.length; i++) {
    //     imagesArray.push(e.target.files[i]);
    // }
    imagesArray.push(img);
    setimages(imagesArray);

    console.log(imagesArray);
  };

  const showImages = (event) => {
    let fileArray = showlist;
    let fileObj = [];
    fileObj.push(event.target.files[0]);

    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }

    // console.log("file array",fileArray)
    // console.log("file obj",fileObj)
  };

  const createBlog = () => {
    setloading(true);

    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    for (let i = 0; i < images.length; i++) {
      data.append("images[]", images[i]);
    }

    // console.log(data)

    axios
      .post(`${baseUrl}/admin/blogs`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("response from server", res.data);
        if (res.data.metadata.success) {
          history.push("/blog");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });

    setimages(newArr2);
    setshowlist(newArr);
  };


  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  console.log(description)

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <h1 className="font-weight-boldest">Add New Blog</h1>
      </div>
      <form
        onSubmit={handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <label className="form-label font-weight-bold font-size-h4" htmlFor="titleLable">
            Title
          </label>
          <input
            id="titleLable"
            placeholder="Put your blog title here..."
            type="text"
            onChange={(e) => settitle(e.target.value)}
            className={`form-control form-control h-auto py-5 px-6`}
            name="title"
          />
        </div>

        <div className="form-group fv-plugins-icon-container">
          <label className="form-label font-weight-bold font-size-h4" htmlFor="titleLable">
            Details
          </label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={(e) => setdescription(e)}
            modules={modules}
            placeholder={"Write something..."}
          />
          {/* <textarea
                        onChange={(e) => setdescription(e.target.value)}
                        className={`form-control form-control py-5 px-6`}
                        rows="4" cols="50" placeholder="Message">

                    </textarea> */}
        </div>

        <div>
          <div >
            <label className="form-label font-weight-bold font-size-h4" htmlFor="titleLable">
              Images
            </label>
            <input
              className="form-control form-control h-auto py-5 px-6"
              name="image"
              onChange={(e) => {
                handleChange(e);
                showImages(e);
              }}
              type={"file"}
              accept="image/png, image/jpeg"
            />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          {showlist.map((pic, i) => (
            <div key={i + 1}>
              <img
                key={i + 1}
                height={150}
                width={150}
                style={{ margin: 5 }}
                src={pic}
              />
              <button
                className="danger"
                onClick={() => removeImage(i)}
                style={{ border: "none" }}
              >
                <p style={{ color: "red" }}>Remove</p>
              </button>
            </div>
          ))}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Add</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewBlog;
