import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from "yup";
import { baseUrl } from '../../../services/config';
import { TokenHeader } from '../../../services/headers';

const EditType = ({ type, editvisible, hide, reload }) => {
    const [loading, setloading] = useState(false)
    const [name, setName] = useState(type.name)

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(name)
        enableLoading()
        fetch(`${baseUrl}/admin/list/category/type/${type.id}?_method=PUT`, {
            method: 'POST',
            headers: TokenHeader,
            body: JSON.stringify({ name })
        }).then((resp) => {
            resp.json().then(data => {
                disableLoading()
                hide()
                reload()

            })
        }).catch((err) => {
            console.log(err)
        })
    }

    const enableLoading = () => {
        setloading(true)
    }
    const disableLoading = () => {
        setloading(false)
    }

    return (
        <Modal onHide={() => hide()} animation={true} show={editvisible}>
            <Modal.Header closeButton={true} closeLabel="close">
                <Modal.Title>
                    Update Type
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework">
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            // value={}
                            placeholder="Category Name"
                            // onChange={(e) => initialValues.name = e.target.value}
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            className={`form-control form-control-solid h-auto py-5 px-6`}
                            defaultValue={type.name}
                            name="name"

                        />

                    </div>

                </form>
                <Modal.Footer>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            id="kt_login_signin_submit"
                            type="submit"

                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 m-3`}
                        >
                            <span>Update</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                        <button
                            onClick={()=>hide()}
                            id="kt_login_signin_submit"
                    

                            className={`btn btn-danger font-weight-bold px-9 py-4 my-3 m-3`}
                        >
                            <span>Cancel</span>
                           
                        </button>
                    </div>
                </Modal.Footer>
            </Modal.Body>

        </Modal>
    )
}

export default EditType