import { Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { payload } from './data.json'
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import { baseUrl } from '../../services/config';
import { TokenHeader } from '../../services/headers';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import EditAmenity from '../../components/EditAmenity/EditAmenity';
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 5,


  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    color: 'black',
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const Amenity = () => {

  const history = useHistory()
  const classes = useStyles();

  const initialValues = { name: "" };
  const [deleteModal, setdeleteModal] = useState(false)
  const [modalVisable, setmodalVisable] = useState(false)
  const [loading, setloading] = useState(false)
  const [list1, setList1] = useState([])
  const [selectedId, setselectedId] = useState('')
  const [selectedObj, setselectedObj] = useState({})
  const [editModal, seteditModal] = useState(false)

  useEffect(() => {
    // getData()
    getCategory()
  }, [])


  const getCategory = () => {
    enableLoading()
    fetch(`${baseUrl}/admin/amenities`, {
      method: 'GET',
      headers: TokenHeader
    }).then(res => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setList1(data.payload)
          disableLoading()
          console.log("List", data.payload)
        }
      })
      .catch(err => {
        console.log(err)
        enableLoading()
      })
  }




  const AddCategorySchema = Yup.object().shape({
    name: Yup.string().required('Please enter Amenity name')
  });

  const getData = async () => {
    fetch(`data.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(res => res.json())
      .then((data) => {
        console.log(data)
      }).catch(err => {
        console.log(err)
      })
  }

  const enableLoading = () => {
    setloading(true)
  }
  const disableLoading = () => {
    setloading(false)
  }


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const formik = useFormik({
    initialValues,
    validationSchema: AddCategorySchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      enableLoading();
      const fdata = new FormData()
      fdata.append('name', values.name)
      axios.post(`${baseUrl}/admin/amenities`, fdata, {
        headers: TokenHeader
      }).then(res => {

        setmodalVisable(false)
        resetForm()
        setSubmitting(false)
        getCategory()
        toast.success(res.data.metadata.message)
      })
        .catch((err) => {
          console.log(err)
        })

    },
  });


  const viewAmenity = item => {
    history.push(`/viewamenity/${item.id}/${item.name}`)
  }

  return (
    <Paper className='p-5'>
      <div className='row justify-content-between p-5'>
        <h1 className='pl-5'>Amenity/Features</h1>
        <div>
          <button
            style={{ color: 'white' }}
            onClick={() => setmodalVisable(true)}
            className='btn btn-primary pr-5 mr-5'>
            Add Amenity
          </button>


        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {
          list1 && list1.map((item) => (
            <Card key={item.id} className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {item.name}
                </Typography>

              </CardContent>
              <CardActions
                style={{
                  justifyContent: 'space-around'
                }}
              >
                <Button
                  onClick={() => viewAmenity(item)}
                  title={'View'} size="small">
                  <VisibilityIcon style={{ color: "green" }} />
                </Button>
                <Button
                  onClick={() => {
                    setselectedObj(item)
                    seteditModal(true)
                  }}
                  title={'Edit'} size="small">
                  <EditIcon style={{ color: "blue" }} />
                </Button>
                <Button
                  onClick={() => {
                    setselectedId(item.id)
                    setdeleteModal(true)
                  }}
                  title='Delete' size="small">
                  <DeleteOutlineIcon style={{ color: "red" }} />
                </Button>
              </CardActions>
            </Card>
          ))
        }

      </div>

      <Modal onHide={() => setmodalVisable(false)} animation={true} show={modalVisable}>
        <Modal.Header closeButton={true} closeLabel="close">
          <Modal.Title>
            Add Amenity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework">
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            ) : (
              null
              // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              //   <div className="alert-text ">
              //     Use account <strong>admin@demo.com</strong> and password{" "}
              //     <strong>demo</strong> to continue.
              //   </div>
              // </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Category Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "name"
                )}`}
                name="name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              ) : null}
            </div>


            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Add</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={deleteModal}
        hideModal={() => setdeleteModal(false)}
        reload={getCategory}
        url={`${baseUrl}/admin/amenities/${selectedId}`}
      />

      
      <EditAmenity
        category={selectedObj}
        reload={getCategory}
        hide={() => seteditModal(false)}
        visible={editModal}

      />
    </Paper>
  )
}

export default Amenity