import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: "360px",
    backgroundColor: theme.palette.background.paper,
    borderRadius:"5px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
  },
}));

export default function ListDividers({ data,loader,onClick,selectedItem }) {
  const classes = useStyles();
  console.log("Selected Index===>",selectedItem)
  useEffect(()=>{
    if(data){
      onClick(1);
    }
  },[])

  return (
    <List component="nav" className={classes.root} aria-label="Mailbox folders">
      {data.map((item, index) => {
        return index < data.length-1 ? (
          item.id===selectedItem?
          <ListItem disabled={true} button key={index} className="bg-dark text-white">
            <ListItemText primary={item.name} />
          </ListItem>
          :
          <>
          <ListItem onClick={()=>onClick(item.id)} disabled={loader} button key={index}>
            <ListItemText primary={item.name} />
          </ListItem>
          <Divider light />
          </>
        ) : (
          <>
            <ListItem onClick={()=>onClick(item.id)} disabled={loader} button key={index}>
              <ListItemText primary={item.name} />
            </ListItem>
           
          </>
        );
      })}
    </List>
  );
}
