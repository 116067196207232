import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { TokenHeader } from '../../../services/headers';
import { baseUrl } from '../../../services/config';
import { TagsInput } from "react-tag-input-component";
import toast from 'react-hot-toast';
import { useEffect } from 'react';


const EditFeature = ({
    visible,
    reload,
    featureObject,
    category,
    hide

}) => {

    const [loading, setloading] = useState(false)
    const [name, setName] = useState(featureObject.name)
    const [placeHolder, setplaceHolder] = useState(featureObject.placeholder)
    const [selectedoption, setselectedoption] = useState(featureObject.input_type)
    const [optionEnable, setoptionEnable] = useState(false)
    const [selected, setSelected] = useState(featureObject.options ? featureObject.options : [])



    const handleSubmit = (e) => {
        e.preventDefault()

        if (name == "" || placeHolder == "") {
            toast.error('Please enter a name and place holder')
        } else if (selectedoption == '') {
            toast.error('Please select input type')
        } else {

            enableLoading()
            let fdata = new FormData()

            fdata.append('amenity_id', category)
            fdata.append('name', name ? name : featureObject.name)
            fdata.append('placeholder', placeHolder ? placeHolder : featureObject.placeholder)
            fdata.append('input_type', selectedoption ? selectedoption : featureObject.input_type)
            for (let i = 0; i < selected.length; i++) {
                fdata.append("options[]", selected[i]);
            }

            for (let j = 0; j < fdata.length; j++) {
                    console.log(fdata[j]);
            }
            axios.post(`${baseUrl}/admin/features/${featureObject.id}?_method=PUT`, fdata, { headers: TokenHeader })
                .then(res => {
                    if (res.data.metadata.success) {
                        toast.success(res.data.metadata.message)
                        disableLoading()
                        hide()
                        reload()
                    } else if (!res.data.metadata.success) {
                        if (typeof (res.data.metadata.message) == 'object') {
                            Object.values(res.data.metadata.message).map(msg => {
                                toast.error(msg)
                            })
                            disableLoading()
                        } else {
                            toast.error(res.data.metadata.message)
                            disableLoading()
                        }
                    }

                })

                .catch((err) => {
                    console.log(err)
                })
        }

    }

    const enableLoading = () => {
        setloading(true)
    }
    const disableLoading = () => {
        setloading(false)
    }




    return (
        <Modal size={'lg'} onHide={() => hide()} animation={true} show={visible}>
            <Modal.Header closeButton={true} closeLabel="close">
                <Modal.Title>
                    Add Feature
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    // onSubmit={handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework">
                    <div className="form-group fv-plugins-icon-container d-flex">
                        <div className='col-md-6'>
                            <input
                                // value={}
                                placeholder="Feature Name"
                                // onChange={(e) => initialValues.name = e.target.value}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="name"
                                defaultValue={featureObject.name}

                            />
                        </div>
                        <div className='col-md-6'>
                            <input
                                placeholder="Enter placeholder"
                                type="text"
                                defaultValue={featureObject.placeholder}
                                onChange={(e) => setplaceHolder(e.target.value)}
                                className={`form-control form-control-solid h-auto py-5 px-6`}

                            />

                        </div>

                    </div>

                    <div className="form-group fv-plugins-icon-container d-flex">

                        <div className='col-md-6'>
                            <select
                                defaultValue={featureObject.input_type}
                                onChange={e => {
                                    if (e.target.value == 'select') {
                                        setselectedoption(e.target.value)
                                        setoptionEnable(true)
                                    } else {
                                        setselectedoption(e.target.value)
                                        setoptionEnable(false)
                                    }

                                }}
                                className='form-control form-control-solid h-auto py-5 px-6'
                            >
                                <option value={''}>Select Input Type</option>
                                {
                                    ['select', 'number', 'text', 'checkbox'].map(op => (
                                        <option value={op}>{op}</option>
                                    ))
                                }
                            </select>

                        </div>
                        {
                            optionEnable || selectedoption == 'select' ?
                                <div className='col-md-6'>
                                    <TagsInput
                                        classNames={'h-auto py-5 px-6'}
                                        value={selected}
                                        onChange={setSelected}
                                        name="options"
                                        placeHolder="Enter options"
                                    />
                                    <em>press enter or comma to add new option</em>
                                </div>
                                : null

                        }

                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>

                {
                    loading ?
                        <Spinner animation='border' />

                        :

                        <div>
                            <button
                                onClick={handleSubmit}
                                id="kt_login_signin_submit"
                                type="submit"
                                className={`btn btn-primary font-weight-bold px-9 py-4 my-3 ml-5`}
                            >
                                Update

                            </button>
                            <button
                                onClick={() => hide()}
                                className={`btn btn-danger font-weight-bold px-9 py-4 my-3 ml-5`}
                            >
                                Cancel

                            </button>
                        </div>


                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditFeature