import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import Category from "./pages/Categories/Category";
import CategoryTypes from "./pages/CategoryTypes/CategoryTypes";
import Property from "./pages/Property/Property";
import Blog from "./pages/Blog/Blog";
import AddNewBlog from "./pages/Blog/AddNewBlog/AddNewBlog";
import Users from "./pages/Users/Users";
import ShowProperty from "./pages/Property/ShowProperty/ShowProperty";
import EditBlog from "./pages/Blog/EditBlog/EditBlog";
import Classifications from "./pages/Classifications/Classifications";
import ViewBlog from "./pages/Blog/ViewBlog/ViewBlog";
import AttachFeatures from "./pages/Categories/AttachFeatures";
import Amenity from "./pages/Amenity/Amenity";
import ViewAmenity from "./pages/ViewAmenity/ViewAmenity";
import Inquiries from "./pages/Inquiries/Inquiries";
import ViewInquire from "./pages/Inquiries/ViewInquire";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute
          roles="admin"
          path="/dashboard"
          component={DashboardPage} />
        <ContentRoute
          roles="admin"
          path="/builder" component={BuilderPage} />
        <ContentRoute roles="admin" path="/category" component={Category} />
        <ContentRoute roles="admin" path="/category-type/:id" component={CategoryTypes} />
        <ContentRoute roles="admin" path="/property" component={Property} />
        <ContentRoute roles="admin" path="/showproperty/:id" exact component={ShowProperty} />
        <ContentRoute roles="admin" path="/blog" component={Blog} />
        <ContentRoute roles="admin" path="/addnewblog" component={AddNewBlog} />
        <ContentRoute roles="admin" path="/users" component={Users} />
        <ContentRoute roles="admin" path="/classifications" component={Classifications} />
        <ContentRoute roles="admin" path="/amenity" component={Amenity} />
        <ContentRoute roles="admin" path="/viewamenity/:id/:slug" component={ViewAmenity} />
        <ContentRoute roles="admin" path="/attach-feature" component={AttachFeatures} />
        <ContentRoute roles="admin" path="/view-blog/:id" component={ViewBlog} />
        <ContentRoute roles="admin" path="/editblog/:id" component={EditBlog} />
        <ContentRoute roles="admin" path="/inquiries" component={Inquiries} />
        <ContentRoute roles="admin" path="/view-inquire/:id" component={ViewInquire} />

        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
