import React, { useEffect, useState } from 'react'
import './blog.css'
import { Table, Modal } from 'react-bootstrap'
import { appColors } from '../../services/colors'
import { Link, useHistory } from 'react-router-dom'
import { baseUrl } from '../../services/config'
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { TokenHeader } from '../../services/headers'
import { Paper } from '@material-ui/core'
import axios from 'axios'

import SVG from "react-inlinesvg";
const Blog = (props) => {
    const history = useHistory()
    const [loading, setloading] = useState(false)
    const [selectedid, setselectedid] = useState('')
    const [deleteModal, setdeleteModal] = useState(false)
    const [deleteloading, setdeleteloading] = useState(false)
    const [list, setlist] = useState([])

    useEffect(() => {
        getBlog()
    }, [])

    const getBlog = () => {
        setloading(true)
        axios({
            url: `${baseUrl}/admin/blogs`,
            method: 'GET',
            headers: TokenHeader,
            responseType: 'json'
        }).then((resp) => {
            console.log(resp.data)
            setlist(resp.data.payload)
            setloading(false)
        })
            .catch(err => {
                console.log(err)
                setloading(false)

            })

    }



    const deleteBlog = (id) => {
        setselectedid(id)
        setdeleteModal(true)
    }


    const deleteBlogHandle = () => {
        setdeleteloading(true)
        axios.delete(`${baseUrl}/admin/blogs/${selectedid}`, {
            headers: TokenHeader
        })
            .then(resp => {
                console.log(resp.data)
                setdeleteModal(false)
                setdeleteloading(false)
                getBlog()
            }).catch(err => {
                console.log(err)
                setdeleteloading(false)
            })

    }

    const viewBlog = (id) => {
        history.push(`view-blog/${id}`);
    }

    const blogShotner=(des)=>{
        if(des.length>50){
           return des.slice(0,50)+'...'
        }
        else{
            return des
        }
    }


    return (
        <Paper className="p-5">
            <div className='container'>
                <div className='blog__header'>
                    <h1>Blogs</h1>
                    <Link to={'/addnewblog'}>
                        <button className='btn btn-primary'>
                            Add new Blog
                        </button>
                    </Link>
                </div>

                <Table className='mt-5' responsive>
                    <thead className='table' style={{ backgroundColor: '#DCDFE2' }}>
                        <th style={{ textAlign: "start", color: 'black' }}>ID</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Name</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Description</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Edit</th>
                        <th style={{ textAlign: "start", color: 'black' }}>View</th>
                        <th style={{ textAlign: "start", color: 'black' }}>Delete</th>
                    </thead>
                    <tbody>
                        {
                            list.map((category, index) => (
                                <tr key={category.id}>
                                    <td>{index + 1}</td>
                                    <td id='text-card'>{category.title}</td>
                                    <td style={{textAlign:"justify"}} id='text-card'>{ blogShotner(category.description)}</td>

                                    <td>
                                        <Link to={{
                                            pathname: `/editblog/${category.id}`,
                                            state: category
                                        }}>

                                            <a className="btn btn-icon btn-light btn-sm">
                                                <span
                                                    className="svg-icon svg-icon-md svg-icon-primary">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Communication/Write.svg"
                                                        )}
                                                    ></SVG>
                                                </span>
                                            </a>

                                        </Link>
                                    </td>
                                    <td>
                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span
                                                onClick={() => viewBlog(category.id)}

                                                className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Devices/Display2.svg"
                                                    )}
                                                ></SVG>
                                            </span>
                                        </a>

                                    </td>
                                    <td>
                                        <a className="btn btn-icon btn-light btn-sm">
                                            <span
                                                onClick={() => deleteBlog(category.id)}

                                                className="svg-icon svg-icon-md svg-icon-danger">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Home/Trash.svg"
                                                    )}
                                                ></SVG>
                                            </span>
                                        </a>

                                    </td>

                                </tr>
                            ))
                        }

                    </tbody>
                </Table>

                {
                    loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span className='spinner spinner-success spinner-large'></span>
                    </div>
                }
                <Modal show={deleteModal} onHide={() => setdeleteModal(false)}>
                    <Modal.Header>
                        <Modal.Title>Delete Blog</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete?
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            deleteloading ?

                                <span className='spinner spinner-border'></span>

                                :
                                <>
                                    <button
                                        onClick={deleteBlogHandle}
                                        className='btn btn-primary'>Yes</button>
                                    <button
                                        className='btn btn-danger'
                                        onClick={() => setdeleteModal(false)}>No</button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        </Paper>
    )
}

export default Blog