import { CircularProgress, Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import ListDividers from "../../../components/ClassificationComponents/ListDividers";
import { baseUrl } from "../../../services/config";
import { TokenHeader } from "../../../services/headers";
import axios from 'axios';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';






const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'green',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: 'green',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});



const AttachFeatures = () => {
  const [categoryId, setCategoryId] = useState(1);
  const [categoriesList, setcategoriesList] = useState([]);
  const [featuresAttach, setFeaturesAttach] = useState([]);
  const [attachedList, setAttachList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesloading, setLoadingCategories] = useState(false);
  let token = localStorage.getItem("userToken");
  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    setLoading(true);

    fetch(`${baseUrl}/admin/categories`, {
      method: "GET",
      headers: TokenHeader,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setcategoriesList(data.payload);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onCategoryClickHandler = async (id) => {
    setCategoryId(id);
    setFeaturesAttach([]);
    // setAttachList([]);
    setLoadingCategories(true);
    await fetch(`${baseUrl}/admin/categories/${id}/features`, {
      method: "GET",
      headers: TokenHeader,
    })
      .then((res) =>
        res.json().then((res) => {
          setLoadingCategories(false);
          setFeaturesAttach(res.payload);
        })
      )

      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const attactListHandler = (id, check) => {
    let tempArr = [...featuresAttach];
    let newArr = [];
    if (check) {
      newArr = tempArr.map((obj) => {
        if (obj.id === id) {
          return { ...obj, attach: true };
        }

        return obj;
      });
      setFeaturesAttach(newArr);
      console.log("NewArr ====> " + JSON.stringify(newArr));
    } else {
      newArr = tempArr.map((obj) => {
        if (obj.id === id) {
          return { ...obj, attach: false };
        }

        return obj;
      });
      setFeaturesAttach(newArr);
      console.log("NewArr ====> " + JSON.stringify(newArr));
    }
    const updatedArr = newArr.filter((item) => item.attach == true);

    setAttachList(updatedArr.map((item) => item.id));
  };

  const onSaveAttachHandler = async () => {
    setLoadingCategories(true)
    const data = new FormData();

    const updatedArr = featuresAttach.filter((item) => item.attach == true);

    let newarr = updatedArr.map((item) => item.id);

    for (let i = 0; i < newarr.length; i++) {
      data.append(`featureIds[${i}]`, newarr[i]);
    }

    for (var pair of data.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    await axios
      .post(
        `${baseUrl}/admin/categories/${categoryId}/attach-features`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoadingCategories(false)
        console.log("response from server", res.data);
      })
      .catch((err) => {
        setLoadingCategories(false)
        console.log(err);
      });
  };


  if (loading) {
    return <CircularProgress />;
  }







  const handleChange = (e, item, index) => {

    let arr = [...featuresAttach]
    let newVal = e.target.checked
    arr[index].attach = newVal
    setFeaturesAttach(arr)

    // console.log(item)
    // console.log(newVal)
    // attachedCategories 


  };


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col col-md-4 col-lg-4">
          <h2 className="shadow-sm p-3 mb-5 bg-white rounded">Select Category</h2>
          <ListDividers
            data={categoriesList}
            loader={categoriesloading}
            onClick={onCategoryClickHandler}
            selectedItem={categoryId}
          />
        </div>
        <div className="col col-md-8 col-lg-8">
          {categoriesloading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <h2 className=" shadow-sm p-3 mb-5 bg-white rounded">Attach Features</h2>
              <div className="bg-white">
                <Table responsive>
                  <thead className="table" style={{ backgroundColor: "#DCDFE2" }}>
                    <th style={{ textAlign: "start", color: "black" }}>ID</th>
                    <th style={{ textAlign: "start", color: "black" }}>Name</th>
                    <th style={{ textAlign: "start", color: "black" }}>Type</th>
                    <th style={{ textAlign: "start", color: "black" }}>Delete</th>
                  </thead>
                  <tbody>
                    {featuresAttach.map((feature, index) => (
                      <tr key={feature.id}>
                        <td>{index + 1}</td>
                        <td id="text-card">{feature.name}</td>
                        <td id="text-card">{feature.input_type == 'checkbox' ? "CheckBox" : feature.input_type == 'number' || feature.input_type == 'text' ? "TextInput" : feature.input_type == 'select' ? "Dropdown" : null}</td>
                        <td style={{ textAlign: "start" }}>
                          <FormControlLabel
                            control={<IOSSwitch checked={feature.attach} onChange={(e) => handleChange(e, feature, index)} name="checkedB" />}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
          <button
            className="btn btn-primary mt-10"
            onClick={onSaveAttachHandler}
            disabled={featuresAttach.length > 0 ? false : true}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttachFeatures;
