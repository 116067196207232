import React from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast'


function DeleteModal({
    show,
    hideModal,
    url,
    reload,
}) {


    let token = localStorage.getItem('userToken')
    const [loading, setloading] = useState(false)


    const deleteFunc = () => {
        setloading(true)
        fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success(data.metadata.message)
                    setloading(false)
                    hideModal()
                    reload()
                } else {
                    setloading(false)
                }
            }).catch(err => {
                console.log(err)
                setloading(false)
            })


    }
    return (
        <Modal
            show={show}
            animation={true}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete?
            </Modal.Body>
            <Modal.Footer>
                {
                    !loading ? <>
                        <button
                            onClick={deleteFunc}
                            className='btn btn-success'>
                            Delete
                        </button>
                        <button
                            onClick={hideModal}
                            className='btn btn-danger'>
                            Cancel
                        </button>
                    </>
                        :
                        <Spinner animation='border' />
                }

            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal