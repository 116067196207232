/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useDispatch } from 'react-redux'

export function Routes() {






  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const dispatch = useDispatch()

  useEffect(() => {

    if (performance.navigation.type == performance.navigation.TYPE_RELOAD && localStorage.getItem('userToken')) {
      dispatch({
        type: 'ADD_USER',
        payload: {
          isAuthenticated: true,

          authtoken: localStorage.getItem('userToken'),
          user: JSON.parse(localStorage.getItem('userDetails'))
        }
      })

    }
  }, [])

  const isAuthenticated = useSelector(state => state.user.isAuthenticated)
  console.log('isAuthenticated1==>',isAuthenticated)

  return (
    <Switch>

      {/* {
        !isAuthenticated ?
          <Route>

            <AuthPage />
          </Route>
          :
          isAuthenticated ?

            (
              <Redirect from="/auth" to="/dashboard" />
            )

            :
            null
      } */}

      {
      !localStorage.getItem('userToken')
        ?
        //Render auth page when user at `/auth` and not authorized.

        <Route>

          <AuthPage />
        </Route>
        : localStorage.getItem('userToken') ?
         
          (
            <Redirect from="/auth" to="/dashboard" />
          )
          : null

    }

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />


      {
        localStorage.getItem('userToken') ?
          (<Layout>
            <BasePage />
          </Layout>
          )
          : (<Redirect to="/auth/login" />)
      }



    </Switch>
  );
}
