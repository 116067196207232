import axios from 'axios'
import React, { useState } from 'react'
import { baseUrl } from '../../../services/config'
import { mediaHeader } from '../../../services/headers'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";



const EditBlog = (props) => {


    const { state } = props.location
    // console.log(props.location.state)
    const history = useHistory()
    const { id } = props.match.params
    const [title, settitle] = useState(state.title)
    const [selectedImage, setselectedImage] = useState('')
    const [description, setdescription] = useState(state.description)
    const [loading, setloading] = useState(false)

    const [images, setimages] = useState([])

    const [oldimages, setOldimages] = useState(state.images)

    const [show, setshow] = useState(false)
    const [showlist, setshowlist] = useState([])
    const [deleteableIds, setdeleteableIds] = useState([]);

    let token = localStorage.getItem('userToken')


    const handleSubmit = (e) => {
        e.preventDefault()
        if (title == "" || description == "") {
            toast.error('please fill all fields')
        } else {
            createBlog()
        }
    }


    const modules = {
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' },
          { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      }



    // const onchangeFile = (e) => {
    //     var file = e.target.files[0];
    //     var reader = new FileReader();
    //     reader.onload = (e) => {
    //       //Image URL e.target.result
    //       console.log(e.target.result)
    //     //   this.setState({
    //     //     form:{
    //     //       frontDesign: [e.target.result]
    //     //     }
    //     //   })
    //     }
    //     reader.readAsDataURL(file);
    //   }


    //   const onImageChange = event => {
    //     if (event.target.files && event.target.files[0]) {
    //       let img = event.target.files[0];
    //     let url =URL.createObjectURL(img)
    //     console.log(url)
    //     }
    //   };




    const onClickoldDel = (id) => {

        let arr = oldimages.filter((item) => item.id == id);
        let arr2 = oldimages.filter((item) => item.id !== id);
        let ids = arr.map((ele) => ele.id);

        setOldimages(arr2);
        setdeleteableIds(ids);
    };



    const handleChange = (e) => {

        let img = e.target.files[0]
        const imagesArray = [...images];
        const imagesArray2 = [...showlist];
        // for (let i = 0; i < e.target.files.length; i++) {
        //     imagesArray.push(e.target.files[i]);
        // }
        imagesArray.push(img)
        imagesArray2.push(URL.createObjectURL(img))
        setshowlist(imagesArray2)
        setimages(imagesArray)

    };


    const createBlog = () => {
        setloading(true)
        const data = new FormData();
        data.append("title", title)
        data.append("description", description)

        for (let i = 0; i < images.length; i++) {
            data.append("images[]", images[i]);
        }

        for (let i = 0; i < deleteableIds.length; i++) {
            data.append("deleteAbleImageIds[]", deleteableIds[i]);
        }

        fetch(`${baseUrl}/admin/blogs/${state.id}?_method=PUT`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: data
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success(data.metadata.message)
                    history.push('/blog')
                }

            })
            .catch(err => {
                console.log(err)
            })



        // axios.post(`${baseUrl}/admin/blogs/${state.id}?_method=PUT`, data, {
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //     }
        // })
        //     .then(res => {
        //         console.log("response from server", res.data)
        //         if (res.data.metadata.success) {
        //             history.push('/blog')
        //         }

        //     }).catch(err => {
        //         console.log(err)
        //     })

    }




    const removeImage = (i) => {
        let arr = [...showlist];
        let arr2 = [...images];
        let newArr = arr.filter((item, index) => {
            return index !== i;
        });
        let newArr2 = arr2.filter((item, index) => {
            return index !== i;
        });

        setimages(newArr2);
        setshowlist(newArr);
    }


    return (
        <div className='container'>
            <h1>Update Blog</h1>
            <form
                onSubmit={handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework">
                <div className="form-group fv-plugins-icon-container">
                    <input
                        value={title}
                        // defaultValue={title}
                        placeholder="Title"
                        // onChange={(e) => initialValues.name = e.target.value}
                        type="text"
                        onChange={(e) => settitle(e.target.value)}
                        className={`form-control form-control h-auto py-5 px-6`}
                        name="title"

                    />

                </div>
                <div

                    className="form-group fv-plugins-icon-container">

                    <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={(e) => setdescription(e)}
                        modules={modules}
                        placeholder={"Write something..."}
                    />
                    {/* <textarea
                        value={description}
                        // defaultValue={description}
                        onChange={(e) => setdescription(e.target.value)}
                        className={`form-control form-control py-5 px-6`}
                        rows="4" cols="50" placeholder="Message">

                    </textarea> */}



                </div>

                <div>

                    <div>
                        <input className="form-control" name="image" onChange={handleChange} type={'file'} />
                    </div>


                </div>

                <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {oldimages.map((img) => (
                            <div className="pic" key={img.id}>
                                <img
                                    key={img.id}
                                    height={150}
                                    width={150}
                                    style={{ margin: 5 }}
                                    src={img.original_url}
                                />
                                <button
                                    className="danger"
                                    onClick={() => onClickoldDel(img.id)}
                                    style={{ border: "none" }}
                                >
                                    <p style={{ color: "red" }}>X</p>
                                </button>
                            </div>
                        ))}
                        {showlist.map((pic, i) => (
                            <div className="pic" key={i + 1}>
                                <img
                                    key={i + 1}
                                    height={150}
                                    width={150}
                                    style={{ margin: 5 }}
                                    src={pic}
                                />
                                <button
                                    className="danger"
                                    onClick={() => removeImage(i)}
                                    style={{ border: "none" }}
                                >
                                    <p style={{ color: "red" }}>X</p>
                                </button>
                            </div>
                        ))}
                    </div>
                    {/* {
                        images.map((pic, i) => (
                            <img
                                key={i + 1}
                                height={100}
                                width={100}
                                src={pic.original_url}
                            />
                        ))
                    } */}


                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"

                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Update

                        </span>
                        {loading && <span className="ml-3 spinner spinner-grow spinner-white"></span>}
                    </button>
                </div>
            </form>




        </div>
    )
}



export default EditBlog